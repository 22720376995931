import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "./voucher.css"
import Marquee from "react-fast-marquee"
import Poly1 from "./images/Poly1.png"
import Poly2 from "./images/Poly2.png"
import Poly3 from "./images/Poly3.png"

const AboutUs = (props) => {
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [floatPosition, setFloatPosition] = useState({ x: 0, y: 0 })

  const animationFrameRef = useRef()

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
      const body = document.body
      const html = document.documentElement
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
      const windowBottom = windowHeight + window.pageYOffset
      const percentage = (windowBottom / docHeight) * 70

      setScrollPercentage(percentage)
    }

    const updatePosition = () => {
      setFloatPosition({
        x: Math.sin(Date.now() / 1000) * 10,
        y: Math.cos(Date.now() / 1000) * 10,
      })
      animationFrameRef.current = requestAnimationFrame(updatePosition)
    }

    window.addEventListener("scroll", handleScroll)
    animationFrameRef.current = requestAnimationFrame(updatePosition)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      cancelAnimationFrame(animationFrameRef.current)
    }
  }, [])

  const calculateAnimation = (start, end) => {
    if (scrollPercentage < start) return 0
    if (scrollPercentage > end) return 100
    return ((scrollPercentage - start) * 100) / (end - start)
  }

  const poly1Animation = calculateAnimation(0, 33)
  const poly2Animation = calculateAnimation(33, 66)
  const poly3Animation = calculateAnimation(66, 100)

  return (
    <div id="about-us" className={`about-us-container ${props.rootClassName} `}>
      <div className="about-us-about-us-div1" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: `url(${Poly1})`,
            transform: `translate(${floatPosition.x}px, ${poly2Animation * 4 + floatPosition.y}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "265px",
            height: "265px",
            backgroundSize: "cover",
            top: "10%",
            right: "500px",
            zIndex: "-10",
          }}
        />
        <div
          style={{
            backgroundImage: `url(${Poly2})`,
            transform: `translate(${floatPosition.y}px, ${poly2Animation * 4 - floatPosition.x}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "268px",
            height: "268px",
            backgroundSize: "cover",
            top: "50%",
            right: "500px",
            zIndex: "-10",
          }}
        />
        <div
          style={{
            backgroundImage: `url(${Poly3})`,
            transform: `translate(${floatPosition.x}px, ${poly2Animation * 4 + floatPosition.y}%) scale(${1 + poly2Animation / 300})`,
            position: "absolute",
            width: "126px",
            height: "135px",
            backgroundSize: "cover",
            top: "20%",
            left: "230px",
            zIndex: "-10",
          }}
        />
        <div className="about-us-about-child1">
          <center style={{ width: "100%" }}>
            <img src="/playground_assets/logo_icona.png" style={{ marginTop: "0px", width: "100px", marginBottom: "30px" }} />
          </center>
          <span className="about-us-text80" style={{ margin: "auto" }}>
            <span className="about-us-text85">Riscatta il tuo vuocher da </span>
            <span className="about-us-text86">100€!</span>
            <br></br>
            <center style={{ width: "100%", height: "150px" }}>
              <div className="contattaci-contattaci-button">
                <a href="/playground_assets/Domini_Golden_Ticket.pdf" download="Domini_Golden_Ticket.pdf" target="_blank">
                  <span className="contattaci-text94">CLICCA QUI</span>
                </a>
              </div>
            </center>
            <span className="about-us-text87" style={{ fontSize: "30px", lineHeight: "30px" }}>
              *spendibile su tutti i nostri servizi entro il 31 dicembre 2024
              <span dangerouslySetInnerHTML={{ __html: " " }}></span>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

AboutUs.defaultProps = {
  rootClassName: "",
  text: "Text",
}

AboutUs.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default AboutUs
