import React, { useRef, useState, useEffect } from "react"
import AboutUs from "../components/about-us"
import "./home.css"

const Home = (props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const homeCaseStoryRef = useRef()
  const [isScrollButtonVisible, setScrollButtonVisible] = useState(true)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartX(e.pageX - homeCaseStoryRef.current.offsetLeft)
    setScrollLeft(homeCaseStoryRef.current.scrollLeft)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - homeCaseStoryRef.current.offsetLeft
    const walk = (x - startX) * 3
    homeCaseStoryRef.current.scrollLeft = scrollLeft - walk
  }

  const handleHomeScrollButtonClick = () => {
    homeCaseStoryRef.current.scrollBy({ left: 3000, behavior: "smooth" })
  }

  const checkScrollEnd = () => {
    const { scrollWidth, clientWidth, scrollLeft } = homeCaseStoryRef.current
    if (scrollLeft + clientWidth >= scrollWidth) {
      setScrollButtonVisible(false)
    } else {
      setScrollButtonVisible(true)
    }
  }

  return (
    <div className="home-container">
      <AboutUs rootClassName="about-us-root-class-name"></AboutUs>
    </div>
  )
}

export default Home
